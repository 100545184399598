import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { history } from '../';
import { AutoPaymentDto, AutoPaymentMonthDto } from '../models/auto-payment';
import { CardTransaction } from '../models/card-transaction';
import { CommonCardPaymentsDto } from '../models/common-card-payments-dto';
import { Expense } from '../models/expense';
import { ModelBase } from '../models/model-base';
import { PaginatedResult } from '../models/Pagination';
import PeopleSummaryByMonthDto from '../models/people-summary-by-month-dto';
import PeopleSummaryByYearDto from '../models/people-summary-by-year-dto';
import { User, UserForLogin } from '../models/User';
import { store } from '../stores/Store';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

axios.interceptors.request.use((config) => {
  // console.log(axios.defaults.baseURL);
  const token = store.commonStore.token;
  if (token) {
    const json = parseJwt(token);
    const exp = json.exp;
    if (Date.now() < exp * 1000) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.log('Token has expired.');
    }
  } else {
    console.log('NO Token.');
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers['pagination'];
    if (pagination) {
      response.data = new PaginatedResult(response.data, JSON.parse(pagination));
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError<any, any>) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === 'string') {
          toast.error(data);
        }
        if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
          history.push('/not-found');
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) modalStateErrors.push(data.errors[key]);
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        toast.error('Unauthorized');
        break;
      case 404:
        history.push('/not-found');
        break;
      case 500:
        store.commonStore.setServerError(data);
        // history.push('/server-error');
        break;
    }
    Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response?.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: () => request.get<User>('/auth'),
  login: (user: UserForLogin) => request.post<User>('/auth/login', user),
};

const GenericData = <T extends ModelBase>(url: string) => {
  return {
    list: () => request.get<T[]>('/' + url),
    listPaged: (params: URLSearchParams) => axios.get<PaginatedResult<T[]>>('/' + url, { params }).then(responseBody),
    details: (id: number) => request.get<T>(`/${url}/${id}`),
    create: (item: T) => request.post<T>('/' + url, item),
    update: (item: T) => request.put<T>(`/${url}/${item.id}`, item),
    delete: (id: number) => request.del<boolean>(`/${url}/${id}`),
  };
};

const Expenses = {
  getExpenseTypeDefaults: (id: number) => request.get<Expense>(`/expenses/defaults/type/${id}`),
  getDuplicates: () => request.get<Expense[]>(`/expenses/duplicates`),
};

const AutoPayments = {
  getMonths: () => request.get<AutoPaymentMonthDto[]>(`/AutoPayments/months`),
  getForMonth: (year: number, month: number) => request.get<AutoPaymentDto[]>(`/AutoPayments/${year}/${month}`),
  process: (items: AutoPaymentDto[]) => request.post<boolean>(`/AutoPayments/payments`, items),
};

const CardTransactions = {
  uploadCardFile: (file: FormData) => request.post<boolean>(`/CardTransactions/addfile/COSTCO`, file),
  getExtraExpenses: () => request.get<Expense[]>(`/CardTransactions/extraexpenses`),
  addNewExpenses: (cardTransactions: CardTransaction[]) => request.post<boolean>(`/CardTransactions/update/expenses`, cardTransactions),
  getCommonCardPaymentMonths: () => request.get<string[]>('/CardTransactions/statementmonths'),
  getCommonCardAmounts: (month: string) => request.get<CommonCardPaymentsDto>(`/CardTransactions/commoncardpayments/${month}`),
};

const IncomeExpense = {
  getAllMonths: () => request.get<PeopleSummaryByMonthDto[]>('IncomeExpense/people/month/all'),
  getAllYears: () => request.get<PeopleSummaryByYearDto[]>('IncomeExpense/people/year/all'),
};

const agent = {
  Account,
  Expenses,
  AutoPayments,
  CardTransactions,
  IncomeExpense,
  GenericData,
};

export default agent;
